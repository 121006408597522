/**
 * Tracking and GoogleAnalytics related methods.
 *
 * We try and keep all of them here to avoid reusing specific snippets.
 * Any additional scripts that should be inserted should also be placed here.
 *
 * We get most of the config values from @util/content/config
 */

import type { SalesChannel } from '@volvo-cars/tracking';
import { LeadsApp } from '@vcc-package/leads-utils/constants';

type AppAbbreviations = 'ARAR' | 'CBCB' | 'CRCR' | 'KMUKMU' | 'ORQR';
const getAppAbbreviationFromAppId = (appId: LeadsApp): AppAbbreviations => {
  switch (appId) {
    case LeadsApp.AccessoryRequest:
      return 'ARAR';
    case LeadsApp.CallbackRequest:
      return 'CBCB';
    case LeadsApp.ContactRequest:
      return 'CRCR';
    case LeadsApp.QuoteRequest:
      return 'ORQR';
    case LeadsApp.KeepMeUpdated:
    default:
      return 'KMUKMU';
  }
};

type FormType = `${'B2B-' | 'B2C-'}${AppAbbreviations}${'' | '-VBS'}`;

/** @deprecated will be phased out alongside the GA4 update */
const sendGASuccessEvent = (
  formId: string,
  formType: string,
  salesChannel?: SalesChannel,
) => {
  window.dataLayer.push({
    event: 'webform_action',
    formId: formId,
    formType: formType,
    gobGroup: 'conversion',
    nonInteraction: '0',
    result: 'success',
    type: 'Webform',
    salesChannel: salesChannel,
  });
};

/** @deprecated will be phased out alongside the GA4 update */
const sendGALoadEvent = (
  formId: string,
  formType: string,
  salesChannel?: string,
) => {
  window.dataLayer.push({
    event: 'webform_action',
    formId: formId,
    formType: formType,
    gobGroup: 'checkout',
    nonInteraction: '1',
    result: 'load',
    type: 'Webform',
    salesChannel: salesChannel,
  });
};

export const brexitConvert = (market: string) =>
  market.toLowerCase() === 'uk' ? 'gb' : market;

export const getGtmFormTags = ({
  appId,
  isB2b,
  isVbs,
  marketId,
}: {
  appId: LeadsApp;
  isB2b?: boolean;
  isVbs?: boolean;
  marketId: string;
}) => {
  const app = getAppAbbreviationFromAppId(appId);
  const formId = `${brexitConvert(marketId).toUpperCase()}${app}-SUMMARY`;
  const formType: FormType = `${isB2b ? 'B2B' : 'B2C'}-${app}${
    isVbs ? '-VBS' : ''
  }`;
  return { formId, formType };
};

export { sendGALoadEvent, sendGASuccessEvent };
