'use client';
import type { MouseEvent, PropsWithChildren } from 'react';
import React, { useCallback, useState } from 'react';

import { useAbTesting } from '@vcc-package/leads-utils/abTesting';
import { globalStyling } from '@vcc-package/leads-utils/constants';
import { sendEvent } from '@vcc-www/utils/personalization';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';

import { FlexFormFieldType } from '../../api';
import type { FormValues } from '../../types/flexibleForm';
import { useLeadsContext } from '../context/leadsContext';

import type { AdditionalProps } from './flexibleForm';

import { FlexibleForm } from '.';

export type LoadingDebouncedType = (value: boolean, event?: MouseEvent) => void;

export type LeadsFormSubmitHandler = (
  data: FormValues,
  recaptchaToken: string,
  setLoadingDebounced: LoadingDebouncedType,
  loading: boolean,
) => Promise<void | undefined | boolean>;
export type LeadsFormErrorHandler = (
  errors: Error,
  setLoadingDebounced: LoadingDebouncedType,
) => void;

export type LeadsFormProps = {
  additionalProps?: AdditionalProps;
  onError: LeadsFormErrorHandler;
  onSubmit: LeadsFormSubmitHandler;
};

export const LeadsForm = ({
  additionalProps,
  onError,
  onSubmit,
  children,
}: PropsWithChildren<LeadsFormProps>) => {
  ///
  // Hooks
  //////////
  const context = useLeadsContext();
  const {
    marketConfig,
    features,
    navigation: { query },
    appId,
  } = context;
  const [loading, setLoading] = useState(false);
  const recaptchaRef = React.useRef<any>();
  let timer = React.useRef<ReturnType<typeof setTimeout> | undefined>();
  const recaptchaKey = marketConfig.recaptchaKey;
  const useRecaptcha = query.testing !== 'true' && !features.disableRecaptcha;

  const formContext = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: additionalProps?.defaultValues
      ? { ...additionalProps.defaultValues }
      : undefined,
  });

  useAbTesting(context, query.disableexperiments === 'true');

  const { handleSubmit } = formContext;

  const setLoadingDebounced: LoadingDebouncedType = useCallback(
    (value: boolean, event?: MouseEvent) => {
      if (event) {
        event.persist();
      }
      if (timer) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        setLoading(value);
        event?.preventDefault();
      }, 0);
    },
    [setLoading],
  );

  const catchError = useCallback(
    (errors: any) => {
      const outerError = new Error('AggregateError', { cause: errors });
      onError(outerError, setLoadingDebounced);
    },
    [onError, setLoadingDebounced],
  );

  const catchSubmit = useCallback(
    async (data: FormValues) => {
      try {
        let recaptchaToken = '';
        if (useRecaptcha && recaptchaRef) {
          recaptchaRef.current?.reset();
          recaptchaToken = await recaptchaRef.current?.executeAsync();
        }
        const emailAddress = data.Email;
        if (emailAddress) {
          const evergageResponse = await sendEvent({
            action: 'Left an email address',
            emailAddress,
            emailOrigin: 'ext_form_' + appId,
          });
          if (evergageResponse) {
            data[FlexFormFieldType.IS_PROFILE_ID__C] =
              evergageResponse.resolvedUserId;
          }
        }

        await onSubmit(data, recaptchaToken, setLoadingDebounced, loading);
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          catchError(e);
        } else if (typeof e === 'string') {
          catchError(new Error(e));
        } else {
          //Unknown error type here. A non Error object or string was thrown.
          //We try to convert it to a string and catch it in the error callback
          catchError(new Error(String(e)));
        }
      }
    },
    [catchError, loading, onSubmit, setLoadingDebounced, useRecaptcha, appId],
  );

  return (
    <FormProvider {...formContext}>
      <form
        onSubmit={handleSubmit(catchSubmit, catchError)}
        noValidate
        className={`flex flex-col ${globalStyling.sectionGap}`}
      >
        <FlexibleForm
          additionalProps={additionalProps}
          loading={loading}
          setLoadingDebounced={setLoadingDebounced}
        />
        {children}
        {useRecaptcha && recaptchaKey && (
          <ReCAPTCHA
            sitekey={recaptchaKey}
            size="invisible"
            ref={recaptchaRef}
            badge="inline"
          />
        )}
      </form>
    </FormProvider>
  );
};
