'use client';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import type { FieldProps } from '@vcc-package/leads-utils/types';
import { FlexFormTestID } from '@vcc-package/leads-utils/types';
import {
  FlexFormFieldType,
  TranslationKey,
} from '@vcc-package/leads-utils/api';
import { getTranslationKey } from '@vcc-package/leads-utils/util';
import { getInputErrorMessages } from '@vcc-package/leads-utils/hooks';

import { ValidatedAddressSearch } from '../../flexible-forms/components';

export const LegalAddressAsyncField = ({
  translate,
  validation,
  customTranslation,
  hideRequired,
}: FieldProps): JSX.Element | null => {
  const { control } = useFormContext();
  const messages = getInputErrorMessages(translate);
  const customTranslationKeys = getTranslationKey(
    customTranslation,
    FlexFormFieldType.LEGAL_ADDRESS_ASYNC,
  );

  return (
    <ValidatedAddressSearch
      hideRequired={hideRequired}
      name={FlexFormFieldType.LEGAL_ADDRESS}
      testid={FlexFormTestID.LegalAddressAsync}
      label={
        customTranslationKeys
          ? translate(customTranslationKeys.label)
          : translate(TranslationKey.ADDRESS_FIELD_LABEL)
      }
      control={control}
      messages={messages}
      maxLength={250}
      waitOnInput={300}
      {...validation.legaladdressasync}
    />
  );
};
