import React, { useEffect, useRef } from 'react';
import VideoOrImage from '@vcc-www/video-or-image';
import type { MediaItemsProps } from './Carousel';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import Thumbnails from './Thumbnails';
import { TrackingProvider } from '@volvo-cars/tracking';
import scrollIntoView from 'scroll-into-view';
import { useSpringCarousel } from '@vcc-www/spring-carousel';
import styles from './Overlay.module.css';
import { Dialog, DialogMain } from '@vcc-package/overlays';

type OverlayProps = {
  title?: string;
  handleClose: () => void;
  mediaItems: Array<MediaItemsProps>;
  aspectRatio: AspectRatio;
  shouldAutoplay?: boolean;
  previousButtonLabel: string;
  nextButtonLabel: string;
  isOverlayOpen: boolean;
};

const OVERLAY_ID = 'slidingGalleryOverlay';

const Overlay = (props: OverlayProps) => {
  const {
    title = '',
    handleClose,
    aspectRatio,
    mediaItems,
    shouldAutoplay = false,
    nextButtonLabel,
    previousButtonLabel,
    isOverlayOpen,
  } = props;
  const { current } = useSpringCarousel();
  const currentIndex = current - 1;
  const refMediaItems = useRef(null) as unknown as {
    [key: number]: HTMLElement;
  };
  const handleScrollIntoView = (i: number) => {
    scrollIntoView(refMediaItems[i], {
      time: 2100,
      ease: (val) => -(Math.cos(Math.PI * val) - 1) / 2, // Simple ease in/out.
      validTarget: (target) => target.id === OVERLAY_ID,
      align: {
        top: 0,
      },
    });
  };

  useEffect(() => {
    if (currentIndex !== 0 && refMediaItems[currentIndex]) {
      setTimeout(() => {
        handleScrollIntoView(currentIndex);
        // Wait a bit for overlay transition.
      }, 400);
    }
  }, [currentIndex, isOverlayOpen]);

  return (
    <TrackingProvider eventLabel="close">
      <Dialog
        title={title}
        open={isOverlayOpen}
        onClose={handleClose}
        size="full"
      >
        <DialogMain>
          <div className="flex-col lg:flex-row h-full overflow-y-hidden">
            <div
              className={`${styles.mediaContainer} overflow-y-auto pr-16`}
              id={OVERLAY_ID}
            >
              {mediaItems.map(
                ({ alt, imageSources, videoSourcesMp4, body }, i) => (
                  <div key={i} ref={(el) => el && (refMediaItems[i] = el)}>
                    <VideoOrImage
                      aspectRatio={aspectRatio}
                      imageSources={imageSources}
                      videoSourcesMp4={videoSourcesMp4}
                      altText={alt}
                      shouldAutoplay={shouldAutoplay}
                      sizes="100vw"
                    />
                    {body ? (
                      <div
                        className={`${styles.descriptionWrapper} overflow-hidden mx-auto mt-16 mb-32 min-h-48 lg:min-h-24 w-full`}
                      >
                        <p className="my-auto mx-24 lg:mx-auto text-secondary text-center">
                          {body}
                        </p>
                      </div>
                    ) : (
                      <div className="h-8" />
                    )}
                  </div>
                ),
              )}
            </div>
            <div className="until-lg:hidden">
              <Thumbnails
                mediaItems={mediaItems}
                refMediaItems={refMediaItems}
                nextButtonLabel={nextButtonLabel}
                previousButtonLabel={previousButtonLabel}
                currentIndex={currentIndex}
                handleScrollIntoView={handleScrollIntoView}
              />
            </div>
          </div>
        </DialogMain>
      </Dialog>
    </TrackingProvider>
  );
};

export default Overlay;
