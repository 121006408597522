'use client';

import { useEffect, useRef } from 'react';
import { FulfillmentInformation } from '@components/fulfillment-information';
import { BASE_URL, PAGE_NAMING } from '@config';
import { useModal } from '@contexts/global-modal/global-modal.provider';
import { useGA4Tracker } from '@hooks/utils/useGA4Tracker';
import type { RegionCode, SiteSlug } from '@volvo-cars/market-sites';
import { getRetailerNavigationUrl } from 'src/markets/helpers';
import { PageName } from 'src/types/page-search-params';
import AccessoryRequestModal from './accessory-request-modal/accessory-request-modal';

type RetailerCTAProps = {
  accessoryRequestFormRolloutSettings?: {
    campaignCode: string | undefined;
    clientId: string | undefined;
    shouldEnableAccessoryRequestForm: boolean | undefined;
  };
  accessoryRequestFormCtaTextContent?: string;
  accessoryTitle?: string;
  fulfillmentInfo?: string;
  page?: PageName;
  siteSlug: SiteSlug;
  regionCode?: RegionCode;
  retailerCtaTextContent: string;
};

export function RetailerCta({
  accessoryRequestFormRolloutSettings,
  accessoryRequestFormCtaTextContent,
  accessoryTitle,
  fulfillmentInfo,
  page,
  siteSlug,
  retailerCtaTextContent,
}: RetailerCTAProps) {
  const tracker = useGA4Tracker();
  const url = useRef(getRetailerNavigationUrl(siteSlug));
  const urlHasBeenFetched = useRef(false);
  const { openModal } = useModal();

  const { campaignCode, clientId, shouldEnableAccessoryRequestForm } =
    accessoryRequestFormRolloutSettings || {};

  useEffect(() => {
    if (!urlHasBeenFetched.current) {
      urlHasBeenFetched.current = true;
      fetch(`${BASE_URL}/api/storefront/retailer-cta-url?siteSlug=${siteSlug}`)
        .then((res) => res.json())
        .then((res) => (url.current = res.navigationUrl))
        .catch(() => getRetailerNavigationUrl(siteSlug));
    }
  }, [siteSlug]);

  function handleRetailerCtaClick() {
    window.open(url.current);

    tracker.customEvent({
      eventAction: 'button|click',
      eventLabel: 'Find a dealer',
      eventCategory: `${page === PAGE_NAMING.CampaignPage ? `Campaign` : 'Purchase'}`,
    });
  }

  function handleAccessoryRequestFormCtaClick() {
    openModal({
      modalId: 'accessory-request-form',
      children: (
        <AccessoryRequestModal
          accessoryTitle={accessoryTitle}
          formRolloutSettings={{
            campaignCode: campaignCode,
            clientId: clientId,
          }}
        />
      ),
    });

    tracker.customEvent({
      eventAction: 'button|click',
      eventLabel: 'Contact a volvo specialist',
      eventCategory: 'Purchase',
    });
  }

  return (
    <div className="flex-col">
      <button
        aria-label="buy-from-partners-button"
        className="button-filled"
        data-color="accent"
        onClick={handleRetailerCtaClick}
        type="button"
      >
        {retailerCtaTextContent}
      </button>
      {shouldEnableAccessoryRequestForm && (
        <button
          aria-label="buy-from-partners-button"
          className="button-outlined mt-16 mb-8"
          data-color="accent"
          onClick={handleAccessoryRequestFormCtaClick}
          type="button"
        >
          {accessoryRequestFormCtaTextContent}
        </button>
      )}
      {fulfillmentInfo && (
        <FulfillmentInformation
          fulfillmentIcon={{
            type: 'office-retailer-40',
            alt: 'Office retailer icon',
          }}
          fulfillmentInfo={fulfillmentInfo}
        />
      )}
    </div>
  );
}
