// Known allowed query parameters
export enum QUERY_PARAMETER {
  additionalinfo = 'additionalinfo',
  b2b = 'b2b',
  b2bcampaigncode = 'b2bcampaigncode',
  brand = 'brand',
  /** Campaign code that will override the default one in the market config */
  campaigncode = 'campaigncode',
  ccedisplayprice = 'ccedisplayprice',
  cceid = 'cceid',
  ccepricing = 'ccepricing',
  cceurl = 'cceurl',
  clientid = 'clientid',
  /** Only used in old quote finished page */
  ctaurl = 'ctaurl',
  /** A custom retailerid that likely does not exist in RDM */
  customretailerid = 'customretailerid',
  emailid = 'emailid',
  /** ModelKey enum of the car */
  model = 'model',
  /** ModelKey enum of the car */
  modelkey = 'modelkey',
  /** PNO id for a given car */
  pno = 'pno',
  /** Used for potential redirect after successful form submit */
  redirecturl = 'redirecturl',
  /** ParmaPartnerCode of a retailer (also called dealerId / retailerId) */
  retailerid = 'retailerid',
  /** Used to skip certain parts of the Leads API settings */
  skip = 'skip',
  /** Sent as "addressCountry" in the forms */
  source = 'source',
  /** Used for disabling Recaptcha when testing */
  testing = 'testing',
  utm_campaign = 'utm_campaign',
  utm_content = 'utm_content',
  utm_medium = 'utm_medium',
  utm_source = 'utm_source',
  utm_term = 'utm_term',
  vbscardatapath = 'vbscardatapath',
  disableexperiments = 'disableexperiments',
}

// Deprecated and/or removed query parameters. Saved here for documentaiton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum DEPRECATED_PARAMETERS {
  // @deprecated was used in AdditionalInformation in old/new Quote
  channel = 'channel',
  // @deprecated removed - used for pre-selecting contact channels
  contactchannels = 'contactchannels',
  /** @deprecated Used on Callback Request /finished page */
  eifcta = 'eifcta',
  // @deprecated and illegal
  languageid = 'languageid',
  // @deprecated
  market = 'market',
  // @deprecated illegal
  marketid = 'marketid',
  // "deprecated removed
  noretailer = 'noretailer',
  // @deprecated & removed - was used for pre-selecting PointsOfInterest
  poiinit = 'poiinit',
  // @deprecated & removed - resets redux state to initial state, clears session storage
  resetstate = 'resetstate',
  // OLD QUOTE - Parameter for VCEP, used for tracking online bookings
  source__c = 'source__c',
  // @deprecated & removed - was used
  testdealer = 'testdealer',
  // deprecated & outdated (?) - the flow type, currently only supports 'request'
  type = 'type',
}
