import type { GA3Event } from '@volvo-cars/tracking/dist/types';
import type { Query } from '@vcc-package/leads-utils/types';
import { LeadsApp, urls } from '@vcc-package/leads-utils/constants';

/**
 * Checks which application page we're on, and if certain vbs-query-parameters are present
 * @returns GA3 formatted eventAction string
 */
export const getGa3EventAction = (
  pathname: string,
  query: Query,
): GA3Event['eventAction'] => {
  const isVbs = !!query.cceurl || !!query?.vbscardatapath;

  const getEventActionFromUrl = () => {
    switch (pathname) {
      case urls.accessory.form:
      case urls.accessory.root:
        return 'accessory-input';
      case urls.callback.root:
        return 'callback-input';
      case urls.contact.form:
      case urls.contact.root:
        return 'contact-input';
      case urls.kmu.form:
      case urls.kmu.root:
        return 'kmu-input';
      case urls.quote.root:
        return 'model selector';
      case urls.quote.retailer:
        return 'quote retailer';
      case urls.quote.form:
        return 'quote input';

      case urls.accessory.finished:
      case urls.callback.finished:
      case urls.contact.finished:
      case urls.kmu.finished:
      case urls.quote.finished:
        return 'confirmation';

      default:
        return 'leads-input';
    }
  };

  return isVbs ? 'vbs ' : '' + getEventActionFromUrl();
};

/**
 * Checks which application is running
 * @returns GA3 formatted eventCategory string
 */
export const getGa3EventCategory = (
  appId: LeadsApp,
): GA3Event['eventCategory'] => {
  switch (appId) {
    case LeadsApp.AccessoryRequest:
      return 'Accessory Request';
    case LeadsApp.CallbackRequest:
      return 'Callback Request';
    case LeadsApp.ContactRequest:
      return 'Contact Request';
    case LeadsApp.KeepMeUpdated:
      return 'KMU Request';
    case LeadsApp.QuoteRequest:
      return 'request a quote';
    default:
      return 'Request';
  }
};
