import React, { useRef } from 'react';
import { ExtendCSS, Flex } from 'vcc-ui';
import { useSpring, animated } from 'react-spring';
import { useInView } from '@vcc-www/hooks';

type SlidingPaginationIndicatorProps = {
  numberOfItems: number;
  currentIndex: number;
  extend?: ExtendCSS;
  onClick?: (index: number) => void;
  isRtlIndicator?: boolean;
};

const SlidingPaginationIndicator: React.FC<SlidingPaginationIndicatorProps> = ({
  numberOfItems,
  currentIndex,
  extend,
  onClick,
  isRtlIndicator,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { current } = ref || {};
  const { clientWidth = 0, offsetLeft } = current || {};
  const itemWidth = clientWidth / numberOfItems || 0;
  const [inViewRef, inView] = useInView<HTMLDivElement>();

  const styles = useSpring({
    from: isRtlIndicator
      ? { transform: `translateX(${clientWidth}px)` }
      : { transform: `translateX(${currentIndex}px)` },
    to: isRtlIndicator
      ? [
          {
            transform: `translateX(${0 - currentIndex * itemWidth}px)`,
          },
        ]
      : [{ transform: `translateX(${currentIndex * itemWidth}px)` }],
    config: { tension: 120 },
  });

  const handleClick = (e: React.MouseEvent) => {
    if (typeof offsetLeft === 'number' && onClick && inView) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const targetIndex = isRtlIndicator
        ? numberOfItems - Math.ceil(x / itemWidth)
        : Math.floor(x / itemWidth);
      onClick(targetIndex);
    }
  };

  return (
    <Flex
      extend={[indicatorBodyCSS, extend]}
      onClick={(e) => handleClick(e)}
      ref={inViewRef}
    >
      <Flex
        ref={ref}
        extend={progressIndicatorCSS}
        data-autoid="slidingGallery:progressIndicator"
      >
        <animated.div style={styles}>
          <Flex extend={activeItemCSS(itemWidth)} />
        </animated.div>
      </Flex>
    </Flex>
  );
};

const progressIndicatorCSS: ExtendCSS = ({ theme: { color } }) => ({
  background: color.primitive.grey400,
  height: 2,
  margin: 'auto 0',
});

const indicatorBodyCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  height: 4 * baselineGrid,
  cursor: 'pointer',
});

const activeItemCSS =
  (width: number): ExtendCSS =>
  ({ theme: { color } }) => ({
    width: width,
    background: color.foreground.primary,
    height: 2,
  });

export default SlidingPaginationIndicator;
