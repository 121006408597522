'use client';

import type { PropsWithChildren } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';

import type {
  CarModel,
  FormLoadData,
  FormSubmitData,
  PageViewData,
  SalesChannel,
} from '@volvo-cars/tracking';
import type {
  CarModelYear,
  Powertrain,
  ProductClass,
} from '@volvo-cars/tracking/dist/types';
import { getMarketSite } from '@volvo-cars/market-sites';
import { PurposeType } from '@vcc-package/leads-utils/api';
import {
  convertStringToBoolean,
  getDynamicFeatureFlags,
} from '@vcc-package/leads-utils/util';
import { subPageNameMap } from '@vcc-package/leads-utils/constants';

import { useLeadsContext } from './leadsContext';
import { useModel } from './modelContext';
import { useRetailer } from './retailerContext';

export type LeadsTrackingContextValues = {
  formLoad?: (params?: { b2b?: boolean }) => FormLoadData;
  formSubmit?: (params?: { b2b?: boolean }) => Promise<FormSubmitData>;
  pageView?: (params?: {
    b2b?: boolean;
  }) => PageViewData & { featureList?: string };
};
export const useLeadsTracking = () => useContext(LeadsTrackingContext);
export const LeadsTrackingContext = React.createContext<
  LeadsTrackingContextValues | undefined
>(undefined);

async function sha256(data: string) {
  const encoder = new TextEncoder();
  const buffer = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

export const objectToStringList = (theObject: { [key: string]: any }) =>
  Object.entries(theObject).reduce((prev, curr) => {
    return `${prev}${prev ? ',' : ''}${curr[0]}=${curr[1]}`;
  }, '') ?? '';

export const useFeatureFlagTracker = () => {
  const { marketConfig, isEmbeddableForms } = useLeadsContext();

  const getFeatureList = useCallback(() => {
    let trackingFlags: Record<string, any> =
      getDynamicFeatureFlags(marketConfig);

    if (isEmbeddableForms) {
      trackingFlags.isEmbeddableForms = true;
    }

    const featureList = objectToStringList(trackingFlags);
    const featureFlags = featureList;

    return { featureList, featureFlags };
  }, [isEmbeddableForms, marketConfig]);

  return { getFeatureList };
};

export const LeadsTrackingContextProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const context = useLeadsContext();
  const {
    pageName,
    purpose,
    navigation: { query, pathname, route },
  } = context;
  const { selectedModel } = useModel();
  const { isInsideSales, selectedRetailer } = useRetailer();
  const { locale, regionCode } = getMarketSite(pathname);
  const { getFeatureList } = useFeatureFlagTracker();
  const isQuoteInsideSales =
    purpose === PurposeType.OFFER_REQUEST && isInsideSales;

  const getCarToken = useCallback(() => query.cceid, [query.cceid]);

  const getCarModel = useCallback(() => {
    return (selectedModel?.gtm?.carModel as CarModel) ?? undefined;
  }, [selectedModel?.gtm?.carModel]);

  const getCarModelId = useCallback(() => {
    return selectedModel?.gtm?.modelId ?? undefined;
  }, [selectedModel?.gtm?.modelId]);

  const getPowerTrain = useCallback(() => {
    return (selectedModel?.gtm?.powerTrain as Powertrain) ?? undefined;
  }, [selectedModel?.gtm?.powerTrain]);

  const getMarketLanguage = useCallback(
    () => locale?.toLocaleLowerCase(),
    [locale],
  );
  const getCountryCode = useCallback(
    () => regionCode?.toLocaleLowerCase(),
    [regionCode],
  );

  const getDealerId = useCallback(() => {
    const dealerId = selectedRetailer?.dealerId;
    return !dealerId || isQuoteInsideSales ? undefined : dealerId;
  }, [isQuoteInsideSales, selectedRetailer?.dealerId]);

  /**
   * Return dealerInfo which for now just explicitly returns the selected retailer's name.
   * If no retailer is selected OR the Quote Inside Sales agent is selected, return undefined.
   */
  const getDealerInfo = useCallback(() => {
    const dealerInfo = selectedRetailer?.name;
    return !dealerInfo || isQuoteInsideSales ? undefined : dealerInfo;
  }, [isQuoteInsideSales, selectedRetailer?.name]);

  // These are using placeholder values for now
  const getCarEngine = useCallback(() => undefined, []);
  const getCarModelYear = useCallback(
    () => selectedModel?.modelYear?.toString() as CarModelYear | undefined,
    [selectedModel?.modelYear],
  );
  const getCrmCampaignCode = useCallback(() => undefined, []);
  const getCustomerType = useCallback(
    (b2bFromForm?: boolean) => {
      const b2bFromQuery = convertStringToBoolean(query.b2b);
      return b2bFromQuery || b2bFromForm ? 'business' : 'private';
    },
    [query.b2b],
  );
  const getProductClass = useCallback(() => {
    if (!selectedModel?.presentation.seriesType) {
      return undefined;
    }
    return selectedModel?.presentation.seriesType?.toLowerCase() as ProductClass;
  }, [selectedModel?.presentation.seriesType]);
  const getPno34 = useCallback(
    () => selectedModel?.pno34,
    [selectedModel?.pno34],
  );

  /**
   * Will be 'online' sales channel either if it's Callback Request, or
   * it's Quote Request AND the selected retailer is the inside sales agent
   */
  const getSalesChannel = useCallback((): SalesChannel | undefined => {
    return purpose === PurposeType.CBV_CALLBACK || isQuoteInsideSales
      ? 'online'
      : undefined;
  }, [isQuoteInsideSales, purpose]);

  const getSubPageName = useCallback(() => {
    return subPageNameMap[route];
  }, [route]);

  /**
   * Hash the email address if it's available
   * This is used to anonymize the email address in the tracking data
   */
  const getEmailHash = useCallback(async () => {
    if (context.submitData?.email) {
      return sha256(context.submitData.email);
    }
    return undefined;
  }, [context.submitData?.email]);

  const leadsTrackingValue = useMemo<LeadsTrackingContextValues>(() => {
    return {
      formLoad: (overrides) => {
        return {
          carEngine: getCarEngine(),
          carModel: getCarModel(),
          carModelId: getCarModelId(),
          carModelYear: getCarModelYear(),
          carToken: getCarToken(),
          countryCode: getCountryCode(),
          crmCampaignCode: getCrmCampaignCode(),
          customerType: getCustomerType(overrides?.b2b),
          dealerId: getDealerId(),
          dealerInfo: getDealerInfo(),
          ...getFeatureList(),
          formType: pageName,
          market: undefined, // GA4 overriding the old provider value
          marketLanguage: getMarketLanguage(), // GA4 overriding the old provider value
          pageName: pageName,
          pageType: 'form',
          powertrain: getPowerTrain(),
          productClass: getProductClass(),
          salesChannel: getSalesChannel(),
          subPageName: getSubPageName(),
          pno34: getPno34(),
        };
      },
      formSubmit: async (overrides) => {
        const emailId = await getEmailHash().catch((err) => {
          console.error('Error hashing email', err);
          return undefined;
        });
        return {
          carEngine: getCarEngine(),
          carModel: getCarModel(),
          carModelId: getCarModelId(),
          carModelYear: getCarModelYear(),
          carToken: getCarToken(),
          countryCode: getCountryCode(),
          crmCampaignCode: getCrmCampaignCode(),
          customerType: getCustomerType(overrides?.b2b),
          dealerId: getDealerId(),
          dealerInfo: getDealerInfo(),
          ...getFeatureList(),
          formType: pageName,
          market: undefined, // GA4 overriding the old provider value
          marketLanguage: getMarketLanguage(), // GA4 overriding the old provider value
          pageName: pageName,
          pageType: 'form',
          powertrain: getPowerTrain(),
          productClass: getProductClass(),
          salesChannel: getSalesChannel(),
          subPageName: getSubPageName(),
          pno34: getPno34(),
          emailId,
        };
      },
      pageView: (overrides) => {
        return {
          carEngine: getCarEngine(),
          carModel: getCarModel(),
          carModelId: getCarModelId(),
          carModelYear: getCarModelYear(),
          carToken: getCarToken(),
          countryCode: getCountryCode(),
          crmCampaignCode: getCrmCampaignCode(),
          customerType: getCustomerType(overrides?.b2b),
          dealerId: getDealerId(),
          ...getFeatureList(),
          formType: pageName,
          market: undefined, // GA4 overriding the old provider value
          marketLanguage: getMarketLanguage(), // GA4 overriding the old provider value
          pageName: pageName,
          pageType: 'form',
          powertrain: getPowerTrain(),
          productClass: getProductClass(),
          salesChannel: getSalesChannel(),
          subPageName: getSubPageName(),
          pno34: getPno34(),
        };
      },
    };
  }, [
    getCarEngine,
    getCarModel,
    getCarModelId,
    getCarModelYear,
    getCarToken,
    getCountryCode,
    getCrmCampaignCode,
    getCustomerType,
    getDealerId,
    getDealerInfo,
    getEmailHash,
    getFeatureList,
    getMarketLanguage,
    getPno34,
    getPowerTrain,
    getProductClass,
    getSalesChannel,
    getSubPageName,
    pageName,
  ]);

  return (
    <LeadsTrackingContext.Provider value={leadsTrackingValue}>
      {children}
    </LeadsTrackingContext.Provider>
  );
};
