'use client';

import React, { useEffect, useState } from 'react';
import { SpringCarouselProvider } from '@vcc-www/spring-carousel';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useTracker } from '@volvo-cars/tracking';
import { useInView, useBreakpoints } from '@vcc-www/hooks';
import { useKeyPress } from '@volvo-cars/react-utils';
import styles from './SlidingGallery.module.css';
import CarouselArrow from './CarouselArrow';
import Carousel, { MediaItemsProps } from './Carousel';
import Overlay from './Overlay';
import type { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';

type SlidingGalleryProps = {
  overlayTitle?: string;
  mediaItems: Array<MediaItemsProps>;
  shouldAutoplay?: boolean;
  disableOverlay?: boolean;
  maxWidth1120?: boolean;
  setInteract?: (hasInteracted: boolean) => void;
  imageDensity?: number;
  hideAssetsSneakPeek?: boolean;
  aspectRatioOverride?: AspectRatio;
  initialCurrent?: number;
};

export const MAX_SLIDE_WIDTH = 1120;

const SlidingGallery = (props: SlidingGalleryProps) => {
  const {
    overlayTitle,
    mediaItems,
    shouldAutoplay = false,
    disableOverlay,
    maxWidth1120,
    setInteract,
    imageDensity,
    hideAssetsSneakPeek,
    aspectRatioOverride,
    initialCurrent,
  } = props;
  const aspectRatio = aspectRatioOverride || {
    default: [4, 5],
    onlyM: [4, 3],
    fromL: [16, 9],
  };
  const translate = useSharedComponentsTranslate();
  const openOverlayLabel = translate('ReactFaqs.labels.showMore');
  const nextButtonLabel = translate(
    'SpringCarousel.springCarousel.nextButtonLabel',
  );
  const previousButtonLabel = translate(
    'SpringCarousel.springCarousel.previousButtonLabel',
  );
  const [inViewRef, inView] = useInView<HTMLDivElement>();
  const [viewRefWidth, setViewRefWidth] = useState(0);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const tracker = useTracker({}, { mode: 'ga3' });
  const ga4Tracker = useTracker({}, { mode: 'ga4' });

  const setHasInteracted = () => {
    if (inView && setInteract) {
      setInteract(true);
    }
  };

  useKeyPress('ArrowLeft', () => setHasInteracted());
  useKeyPress('ArrowRight', () => setHasInteracted());

  const handleClose = () => {
    setIsOverlayOpen(false);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setViewRefWidth(entry.contentRect.width);
      }
    });

    if (inViewRef.current) {
      resizeObserver.observe(inViewRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [inViewRef, setViewRefWidth]);

  // eslint-disable-next-line vcc-www/use-breakpoints
  const { onlyM, onlyS } = useBreakpoints();

  // Using the highest of these values to match the current slide width
  const desktopCarouselContainerWidth = maxWidth1120
    ? MAX_SLIDE_WIDTH
    : Math.max(Math.round(viewRefWidth * 0.7), MAX_SLIDE_WIDTH);

  const maxWidth1120GutterSize =
    ((inViewRef?.current?.offsetWidth || MAX_SLIDE_WIDTH) - MAX_SLIDE_WIDTH) /
    2;

  // 15% of viewport width on each side, making the active slide 70% of viewport
  // The MAX_SLIDE_WIDTH value is used for smaller desktop screens
  const gutterSize = Math.round(
    Math.min(
      viewRefWidth * 0.3,
      (viewRefWidth || MAX_SLIDE_WIDTH) - MAX_SLIDE_WIDTH,
    ) / 2,
  );
  const startEndGutter = onlyS
    ? 24
    : onlyM
      ? 80
      : maxWidth1120
        ? maxWidth1120GutterSize
        : gutterSize;

  return (
    <div
      ref={inViewRef}
      className={styles.container}
      style={
        {
          '--desktop-carousel-container-width': `${desktopCarouselContainerWidth}px`,
          '--start-end-gutter': `${startEndGutter * 2}px`,
        } as React.CSSProperties
      }
    >
      <SpringCarouselProvider
        enableKeyboardNavigation={!isOverlayOpen && inView}
        enableKeyboardTracker={!isOverlayOpen && inView}
        initialCurrent={initialCurrent}
        onChange={(e) => {
          if (e.type === 'swipe-pane') {
            const eventLabel = e.previous < e.current ? 'right' : 'left';
            tracker.interaction({
              eventAction: 'swipe',
              eventLabel,
            });
            ga4Tracker.interaction({
              eventAction: 'carousel|swipe',
              eventLabel,
            });
          }
        }}
      >
        <div
          className={`${styles.mediaContainer} ${
            hideAssetsSneakPeek ? styles.hideAssetsSneakPeek : ''
          }`}
        >
          <Carousel
            mediaItems={mediaItems}
            aspectRatio={aspectRatio}
            setHasInteracted={setHasInteracted}
            isOverlayOpen={isOverlayOpen}
            shouldAutoplay={shouldAutoplay}
            openOverlayLabel={openOverlayLabel}
            disableOverlay={disableOverlay}
            setIsOverlayOpen={setIsOverlayOpen}
            imageDensity={imageDensity}
          />
          <CarouselArrow
            direction="left"
            nextButtonLabel={nextButtonLabel}
            previousButtonLabel={previousButtonLabel}
            setHasInteracted={setHasInteracted}
          />
          <CarouselArrow
            direction="right"
            nextButtonLabel={nextButtonLabel}
            previousButtonLabel={previousButtonLabel}
            setHasInteracted={setHasInteracted}
          />
        </div>
        <Overlay
          title={overlayTitle}
          handleClose={handleClose}
          mediaItems={mediaItems}
          aspectRatio={aspectRatio}
          nextButtonLabel={nextButtonLabel}
          previousButtonLabel={previousButtonLabel}
          shouldAutoplay={shouldAutoplay}
          isOverlayOpen={isOverlayOpen}
        />
      </SpringCarouselProvider>
    </div>
  );
};

export default SlidingGallery;
