import { BASE_URL } from '@config';
import { SiteSlug } from '@volvo-cars/market-sites';

export type MarketConfig = {
  linkToRetailer: string;
  retailerTextContent: string;
  excludedProductTypes: string[];
  enablePriceForProducts: string[];
};

type MarketsCollection = { [key: string]: MarketConfig };

export const defaultMarketConfig = (siteSlug: string): MarketConfig => ({
  linkToRetailer: `${BASE_URL}/${siteSlug}/dealers`,
  retailerTextContent: 'App.CTA.retailer',
  excludedProductTypes: [''],
  enablePriceForProducts: ['performance-mode'],
});

const markets: MarketsCollection = {
  se: {
    ...defaultMarketConfig('se'),
    linkToRetailer: 'https://kgkladdbarafordon.se/volvo/',
    retailerTextContent: 'App.CTA.inquiry',
    excludedProductTypes: ['insurance'],
  },
  nl: {
    ...defaultMarketConfig('nl'),
    linkToRetailer: 'https://ccforms.fillout.com/volvo-laadpaal-aanvraag',
  },
  // Add other markets here
};

export function getMarketConfigs(market: SiteSlug): MarketConfig {
  return markets[market] || defaultMarketConfig(market);
}
