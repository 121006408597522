import React, { useRef, useState, useEffect, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import { ExtendCSS, Flex, ThemePicker } from 'vcc-ui';
import { Icon } from '@volvo-cars/react-icons';
import { Click } from '@vcc-www/buttons';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import { MediaItemsProps } from './Carousel';
import { useTracker } from '@volvo-cars/tracking';

export type ThumbnailProps = {
  mediaItems: Array<MediaItemsProps>;
  refMediaItems: { [key: number]: HTMLElement };
  previousButtonLabel: string;
  nextButtonLabel: string;
  currentIndex: number;
  handleScrollIntoView: (i: number) => void;
};

const HEIGHT_OF_THUMB_CONTAINER = 372; // 6 items max to show
const HEIGHT_OF_THUMB = 62; // Height of thumb + spacing: 54 + 8

const Thumbnails = (props: ThumbnailProps) => {
  const {
    mediaItems,
    refMediaItems,
    previousButtonLabel,
    nextButtonLabel,
    currentIndex,
    handleScrollIntoView,
  } = props;
  const [activeItem, setActiveItem] = useState(currentIndex);
  const [isUpVisible, toggleUpArrow] = useState(false);
  const [isDownVisible, toggleDownArrow] = useState(true);
  const thumbnailRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const tracker = useTracker(
    {},
    { mode: 'both', ga3: { eventAction: 'click' } },
  );

  const displayNone = useMemo(() => ({ display: 'none' }), []);
  const displayFlex = useMemo(() => ({ display: 'flex' }), []);
  const [upArrowProps, setUpArrow] = useSpring(() => displayNone);
  const [downArrowProps, setDownArrow] = useSpring(() => displayFlex);
  const [translateProps, setTranslateY] = useSpring(() => ({ y: 0 }));
  const totalContainerHeight = mediaItems.length * HEIGHT_OF_THUMB;
  useEffect(() => {
    setActiveItem(currentIndex);
  }, [currentIndex]);
  useEffect(() => {
    if (focusedIndex !== null && focusedIndex < 5) {
      setUpArrow.start(displayNone);
      toggleUpArrow(false);
      if (mediaItems.length > 5) {
        setDownArrow.start(displayFlex);
        toggleDownArrow(true);
      }
      setTranslateY.start({ y: 0 });
    }

    if (
      (activeItem >= 5 && focusedIndex === null) ||
      (focusedIndex !== null && focusedIndex >= 5)
    ) {
      setUpArrow.start(displayFlex);
      toggleUpArrow(true);

      const currentThumbPos =
        ((focusedIndex !== null ? focusedIndex : activeItem) + 1) *
        HEIGHT_OF_THUMB;
      const isLast = currentThumbPos === totalContainerHeight;
      const hideDownArrow =
        totalContainerHeight - currentThumbPos === HEIGHT_OF_THUMB || isLast;

      if (hideDownArrow) {
        setDownArrow.start(displayNone);
        toggleDownArrow(false);
      } else {
        setDownArrow.start(displayFlex);
        toggleDownArrow(true);
      }

      setTranslateY.start({
        y: -(
          currentThumbPos +
          (isLast ? 0 : HEIGHT_OF_THUMB) -
          HEIGHT_OF_THUMB_CONTAINER
        ),
      });
    }
  }, [
    activeItem,
    focusedIndex,
    displayFlex,
    displayNone,
    setDownArrow,
    setTranslateY,
    setUpArrow,
    totalContainerHeight,
    mediaItems,
  ]);

  const handleArrowClick = (isUpClick?: boolean) => {
    if (translateProps.y.isAnimating) {
      return;
    }
    const currentTransition = translateProps.y.get();
    const nextTransition =
      (isUpClick ? HEIGHT_OF_THUMB : -HEIGHT_OF_THUMB) + currentTransition;

    const hideArrows = isUpClick
      ? HEIGHT_OF_THUMB_CONTAINER + nextTransition >= HEIGHT_OF_THUMB_CONTAINER
      : totalContainerHeight - HEIGHT_OF_THUMB_CONTAINER ===
        nextTransition * -1;
    setTranslateY.start({ y: nextTransition });

    if (isUpClick) {
      downArrowProps.display.get() === 'none' &&
        setDownArrow.start(displayFlex) &&
        toggleDownArrow(true);
      hideArrows && setUpArrow.start(displayNone) && toggleUpArrow(false);
    } else {
      upArrowProps.display.get() === 'none' &&
        setUpArrow.start(displayFlex) &&
        toggleUpArrow(true);
      hideArrows && setDownArrow.start(displayNone) && toggleDownArrow(false);
      // Show up arrow when transition down occurs.
      nextTransition === -HEIGHT_OF_THUMB &&
        setUpArrow.start(displayFlex) &&
        toggleUpArrow(true);
    }
  };

  const handleThumbClick = (i: number, description?: string) => {
    if (refMediaItems?.[i]) {
      tracker.interaction({
        eventAction: 'image|click',
        eventLabel: `thumbnail | ${i}${description ? ` | ${description}` : ''}`,
      });
      setActiveItem(i);
      handleScrollIntoView(i);
    }
  };

  return (
    <Flex extend={thumbsContainerCSS}>
      {mediaItems.length > 6 && (
        <ThemePicker variant="light">
          <animated.div style={upArrowProps} aria-hidden={!isUpVisible}>
            <Click
              extend={[arrowWrapperCSS, thumbImgCSS()]}
              onClick={() => handleArrowClick(true)}
              aria-label={previousButtonLabel}
            >
              <Flex extend={thumbIconCSS}>
                <Icon icon="chevron-up" size={12} color="currentColor" />
              </Flex>
            </Click>
          </animated.div>
          <animated.div style={downArrowProps} aria-hidden={!isDownVisible}>
            <Click
              extend={[arrowWrapperCSS, arrowDownCSS, thumbImgCSS()]}
              onClick={() => handleArrowClick()}
              aria-label={nextButtonLabel}
            >
              <Flex extend={thumbIconCSS}>
                <Icon icon="chevron-down" size={12} color="currentColor" />
              </Flex>
            </Click>
          </animated.div>
        </ThemePicker>
      )}
      <animated.div
        ref={thumbnailRef}
        style={translateProps}
        data-testid="thumbsWrapper"
      >
        {mediaItems.map(({ imageSources, alt = '', body }, i) => {
          return (
            imageSources.onlyM && (
              <Click
                onFocus={() => {
                  setFocusedIndex(i);
                }}
                onBlur={() => {
                  setFocusedIndex(null);
                }}
                key={i}
                extend={thumbButtonCSS}
                onClick={() => handleThumbClick(i, body)}
              >
                <Image_DEPRECATED
                  extend={thumbImgCSS(i === activeItem)}
                  src={imageSources.onlyM}
                  alt={alt}
                  objectFit="cover"
                  loading="native-lazy"
                  aspectRatio="responsive"
                  sizes="72px"
                />
              </Click>
            )
          );
        })}
      </animated.div>
    </Flex>
  );
};

const thumbsContainerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  position: 'sticky',
  top: baselineGrid * 4,
  height: HEIGHT_OF_THUMB_CONTAINER,
  overflow: 'hidden',
  zIndex: 1,
  paddingInline: `${baselineGrid * 2}px ${baselineGrid}px`,
});

const thumbButtonCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginBottom: baselineGrid,
});

const arrowWrapperCSS: ExtendCSS = {
  position: 'absolute',
  zIndex: 1,
  // Since we need the whole thumb click area.
  '&:active': {
    '> div': {
      width: 20,
      height: 20,
    },
  },
};

const arrowDownCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  bottom: baselineGrid,
});

const thumbImgCSS =
  (isActive?: boolean): ExtendCSS =>
  ({ theme: { color } }) => ({
    width: 72,
    height: 54,
    borderRadius: 4,
    ...(isActive && { border: `2px solid ${color.foreground.primary}` }),
  });

const thumbIconCSS: ExtendCSS = ({ theme: { color } }) => ({
  transition: 'background-color 100ms ease-in',
  background: color.background.primary,
  color: color.foreground.primary,
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  width: 24,
  height: 24,
  margin: 'auto',
});

export default Thumbnails;
