import type { SiteSlug } from '@volvo-cars/market-sites';
import type {
  MarketConfiguration,
  PurposeType,
  RequestApiModel,
} from '@vcc-package/leads-utils/api';
import {
  AddressSearchService,
  ConfigurationService,
  DealersService,
  FlexFormService,
  OpenAPI,
  RequestService,
  TranslationService,
  ValidationsService,
  VehiclesService,
} from '@vcc-package/leads-utils/api';
import type { LeadsApp } from '@vcc-package/leads-utils/constants';

import type { PublicRuntimeConfig } from './config';
import {
  FF_SESSION_KEY,
  getFeatureFlagHeader,
  getGaCookie,
} from './featureFlags';
import type { CookieSetter } from './cookie';

interface ApiClientProps {
  appId: LeadsApp;
  clientId?: string;
  isTesting?: boolean;
  siteSlug: SiteSlug;
  publicRuntimeConfig: PublicRuntimeConfig;
  featureFlagHeader: string | null;
  ssrCookies?: string;
  setCookie: CookieSetter;
}

export const requestId = () =>
  `${Date.now()}#${Math.random().toString(36).substring(2, 10)}`;

type ArrayParameters<T extends (...args: any) => any> = Array<any> &
  Parameters<T>;
type RequiredOpenAPIHeaders = Exclude<(typeof OpenAPI)['HEADERS'], undefined>;

const lockQueue: Array<Promise<unknown>> = [];

function withHeaders<T extends (...args: any) => Promise<any>>(
  baseHeaders: RequiredOpenAPIHeaders,
  fn: T,
) {
  return async function <A extends ArrayParameters<T>, R extends ReturnType<T>>(
    ...args: A
  ): Promise<R | never> {
    // Wait for all previous requests to finish
    await Promise.all(lockQueue);

    const originalHeaders = OpenAPI.HEADERS;
    const specialHeaders = {
      'VCC-Api-Operation': requestId(),
    };

    const lock = fn(...(args as any[]));
    lockQueue.push(lock);

    try {
      OpenAPI.HEADERS = {
        ...(baseHeaders ?? {}),
        ...specialHeaders,
      };

      return await lock;
    } finally {
      lockQueue.splice(lockQueue.indexOf(lock), 1);
      OpenAPI.HEADERS = originalHeaders;
    }
  };
}

function getFFHeader(
  featureFlagHeader: string | null,
  siteSlug: SiteSlug,
  ssrCookies: string | undefined,
  setCookie: CookieSetter,
) {
  const cookies =
    typeof document !== 'undefined' ? document.cookie : ssrCookies ?? null;

  const baseHeader = getFeatureFlagHeader(
    cookies,
    featureFlagHeader,
    siteSlug,
  ).header;
  const gaCookie = getGaCookie({
    cookies,
    setter: setCookie,
  });

  if (baseHeader?.match(/#GA/)) {
    return baseHeader;
  }
  return '#' + gaCookie;
}

/**
 * Leads Forms Backend API client
 */
export type API = ReturnType<typeof APIClient>;
export const APIClient = ({
  clientId,
  siteSlug,
  isTesting,
  publicRuntimeConfig,
  featureFlagHeader,
  ssrCookies,
  setCookie,
}: ApiClientProps) => {
  OpenAPI.BASE = publicRuntimeConfig.apiHost;

  const headers: RequiredOpenAPIHeaders = {};

  // authHeader is important to set so that we can authenticate to test/qa system.
  // Otherwise, we need to log in with CDSID
  if (publicRuntimeConfig?.authHeaderName) {
    headers[publicRuntimeConfig.authHeaderName] =
      publicRuntimeConfig.authHeaderValue;
  }

  if (isTesting) {
    headers.SKIPRECAPTCHA = 'true';
  }

  const gaCookie = getGaCookie({
    cookies:
      typeof document !== 'undefined' ? document.cookie : ssrCookies ?? null,
    setter: setCookie,
  });
  if (gaCookie) {
    headers._ga = gaCookie;
  } else {
    console.info('No GA cookie found');
  }

  const ffHeader = getFFHeader(
    featureFlagHeader,
    siteSlug,
    ssrCookies,
    setCookie,
  );
  Object.defineProperty(headers, FF_SESSION_KEY, {
    get() {
      const ffHeaderValue =
        getFFHeader(featureFlagHeader, siteSlug, ssrCookies, setCookie) ??
        ffHeader ??
        '';

      if (!ffHeaderValue.match(/#GA/)) {
        //We need to have GA in the header to be able to track the feature flag usage
        return undefined;
      }

      return ffHeaderValue;
    },
    enumerable: true,
    configurable: false,
  });

  if (publicRuntimeConfig.useCors) {
    OpenAPI.MODE = 'cors';
    OpenAPI.CREDENTIALS = 'include';
  }

  return {
    searchAddress: withHeaders(
      headers,
      async (params: {
        address: string;
        mainlandFilter: boolean;
        useLoqate: boolean;
      }) => {
        return await AddressSearchService.getAddress({
          clientId,
          siteSlug,
          ...params,
        });
      },
    ),

    getMarketConfig: withHeaders(
      headers,
      async () =>
        await ConfigurationService.getConfiguration({ clientId, siteSlug }),
    ),

    getMarketConfigExtract: withHeaders(
      headers,
      async (settings: Array<keyof MarketConfiguration>) =>
        await ConfigurationService.getConfigurationextract({
          clientId,
          siteSlug,
          settings,
        }),
    ),

    getDealers: withHeaders(
      headers,
      async () =>
        await DealersService.getAllDealers({
          clientId,
          siteSlug,
        }),
    ),

    getDealer: withHeaders(
      headers,
      async (dealerId: string) =>
        await DealersService.getDealerByParmaPartnerCode({
          clientId,
          siteSlug,
          parmaPartnerCode: dealerId,
        }),
    ),

    getDealersByAddress: withHeaders(
      headers,
      async (params: { address: string; limit?: number; offset?: number }) =>
        await DealersService.getDealersByAddress({
          clientId,
          siteSlug,
          ...params,
        }),
    ),

    getDealersByZipCode: withHeaders(
      headers,
      async (zipCode: string) =>
        await DealersService.getDealersByZipCode({
          clientId,
          siteSlug,
          zipCode,
        }),
    ),

    getDealersByCoordinates: withHeaders(
      headers,
      async (params: {
        latitude: number;
        longitude: number;
        limit?: number;
        offset?: number;
      }) =>
        await DealersService.getDealersByCoordinates({
          clientId,
          siteSlug,
          ...params,
        }),
    ),

    getFlexFormConfig: withHeaders(
      headers,
      async (purpose: PurposeType) =>
        await FlexFormService.getFlexFormByPurpose({
          clientId,
          siteSlug,
          purpose,
        }),
    ),

    getTranslations: withHeaders(
      headers,
      async () =>
        await TranslationService.getAllTranslations({
          clientId,
          siteSlug,
        }),
    ),

    validateEmail: withHeaders(
      headers,
      async (userEmail: string) =>
        await ValidationsService.getValidateEmail({
          clientId,
          siteSlug,
          userEmail,
        }),
    ),

    validatePhone: withHeaders(
      headers,
      async (phoneNumber: string) =>
        await ValidationsService.getValidatePhone({
          clientId,
          siteSlug,
          phoneNumber,
        }),
    ),

    sendRequest: withHeaders(
      headers,
      async (data: RequestApiModel) =>
        await RequestService.postApiFormsBackendV1Request({
          clientId,
          siteSlug,
          requestBody: data,
        }),
    ),

    getVehicles: withHeaders(
      headers,
      async (purpose: PurposeType | undefined) =>
        await VehiclesService.getVehicles({
          clientId,
          siteSlug,
          purpose,
        }),
    ),

    getFullConfig: withHeaders(
      headers,
      async (params: { purpose: PurposeType; clientId?: string }) =>
        await ConfigurationService.getFull({
          clientId,
          siteSlug,
          ...params,
        }),
    ),
  };
};
